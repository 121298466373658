import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PatientHistoriesService } from '../../pages/custom/patientHistories/patientHistories.service';
import { PatientHistory } from '../../core/model';

@Component({
  selector: 'app-cmntcount',
  templateUrl: './cmntcount.component.html',
  styleUrls: ['./cmntcount.component.scss']
})
export class CmntcountComponent implements OnInit {
  @Input() item;
  historyData: PatientHistory[] = [];
  PatHistoryListImgs: any = [];
  constructor( private patientHistoriesService: PatientHistoriesService,) { }


  ngOnInit() {
    this.calculateTotal();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.cartData){
      this.calculateTotal();
    }

  }
  calculateTotal() {
    console.log("comment Report112", this.item);
    this.patientHistoriesService
      .getCommentsData(this.item.id)
      .subscribe((data: any) => {
        // console.log('data frm viewCommentReport',data);
        this.historyData = (data as any).commentdata;
        this.patientHistoriesService
        .getCommentsData(this.item.id)
        .subscribe((data) => {
          // console.log('data frm viewCommentReport',data);
          let da = (data as any).patienthistory;
          this.PatHistoryListImgs = da.filter(
            (a) => a.historyFile
          );
          // this.getPatHistoryList(item);
          //  this.getHistoryFileList()
        });
      });
  }

}
