import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {

  transform(value: any[], filterBy: any, column: string, condition = '=', dataType = 'string'): any[] {
    if (dataType == 'string') {
      filterBy = filterBy ? filterBy.toLocaleLowerCase() : null;
      if (condition == '=') {
        return filterBy ? value.filter((item: any) =>
          item[column].toLocaleLowerCase().indexOf(filterBy) !== -1) : value;
      }

      if (condition == '!=') {
        return filterBy ? value.filter((item: any) =>
          item[column].toLocaleLowerCase().indexOf(filterBy) == -1) : value;
      }
    }
    if (dataType == 'int') {
      console.log('filter', value, filterBy, column);
      if (condition == '=') {
        return filterBy ? value.filter((item: any) =>
          item[column] == filterBy) : value;
      }

      if (condition == '!=') {
        return filterBy ? value.filter((item: any) =>
          item[column] != filterBy) : value;
      }
    }


    // if(condition == 'in'){
    //   return filterBy ? value.filter((item: any) =>
    //     item[column].toLocaleLowerCase().indexOf(filterBy) == -1) : value;
    // }

    // return filterBy ? value.filter((item: any) =>
    //     item[column].toLocaleLowerCase().indexOf(filterBy) !== -1) : value;
  }
}
