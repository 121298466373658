import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../../shared/helperService';

@Component({
  selector: 'app-footer-tabs',
  templateUrl: './footer-tabs.component.html',
  styleUrls: ['./footer-tabs.component.scss'],
})
export class FooterTabsComponent implements OnInit {
  activeTab = '';
  pages = [];
  loginPopup: boolean = false;
  @Input() showTabs = true;


  constructor(private helperService: HelperService, private router: Router) { }

  ngOnInit() {
    this.pages = this.helperService.getFooterPages();
    this.activeTab = this.helperService.getCurrentRouteUrl();
   }
   closePopup(value:any){
    this.loginPopup=value;
  }
  GotoTab(path: any): void {
    this.helperService.routeNavigate(path);
  }




}
