import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { map, catchError, switchMap, retry, filter } from 'rxjs/operators';
import { AsyncValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

import * as services from '../core/service';

 import { LookupServiceBase } from '../sharedBase/lookupService';
//import { LookupServiceBase } from '@ojas-enterprise/baseapp/dist/sharedBase';
import { AppInjector } from '../../app-injector.service';

@Injectable({
  providedIn: 'root'
})
export class LookupService extends LookupServiceBase {

  getService(type: string) {
    let service: any = null;
    const injector = AppInjector.getInjector();
    //console.log('injector', injector);
    type = type[0].toLowerCase() + type.substring(1);
    switch (type) {

      case 'user':
        service = injector.get(services.UsersService);
        break;
 case 'webUser':
service = injector.get(services.WebUsersService);
break;
 case 'enumDetail':
service = injector.get(services.EnumDetailsService);
break;
 case 'appUser':
service = injector.get(services.AppUsersService);
break;
 case 'commonData':
service = injector.get(services.CommonDatasService);
break;
 case 'commonDataDefault':
service = injector.get(services.CommonDataDefaultsService);
break;
 case 'configuration':
service = injector.get(services.ConfigurationsService);
break;
 case 'contactUs':
service = injector.get(services.ContactUsService);
break;
 case 'contentHtml':
service = injector.get(services.ContentHtmlsService);
break;
 case 'contentHtmlDefault':
service = injector.get(services.ContentHtmlDefaultsService);
break;
 case 'doctorCharge':
service = injector.get(services.DoctorChargesService);
break;
 case 'doctorClient':
service = injector.get(services.DoctorClientsService);
break;
 case 'document':
service = injector.get(services.DocumentsService);
break;
 case 'home':
service = injector.get(services.HomesService);
break;
 case 'i18n':
service = injector.get(services.I18nService);
break;
 case 'importData':
service = injector.get(services.ImportDatasService);
break;
 case 'otp':
service = injector.get(services.OtpsService);
break;
 case 'recharge':
service = injector.get(services.RechargesService);
break;
 case 'report':
service = injector.get(services.ReportsService);
break;
 case 'reportDetail':
service = injector.get(services.ReportDetailsService);
break;
 case 'totalRecharge':
service = injector.get(services.TotalRechargesService);
break;
 case 'dealer':
service = injector.get(services.DealersService);
break;
 case 'dealerTransaction':
service = injector.get(services.DealerTransactionsService);
break;
 case 'doctorTransaction':
service = injector.get(services.DoctorTransactionsService);
break;
 case 'template':
service = injector.get(services.TemplatesService);
break;
 case 'modality':
service = injector.get(services.ModalitiesService);
break;
 case 'patientHistory':
service = injector.get(services.PatientHistoriesService);
break;
 case 'reportFile':
service = injector.get(services.ReportFilesService);
break;
 case 'reportHistory':
service = injector.get(services.ReportHistoriesService);
break;
 case 'forgetPassword':
service = injector.get(services.ForgetPasswordsService);
break;
 case 'allDocumentUpload':
service = injector.get(services.AllDocumentUploadsService);
break;
// <<add-service>>
    }
    //console.log('service', service);
    return service;
  }

}
