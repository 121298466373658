import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { HelperService } from "../../shared/helperService";
import { Router } from "@angular/router";
import { AppInjector } from "../../../app-injector.service";
import { LoginsService, RechargesService, TotalRechargesService } from "../../core/service";
import { AppUsersService } from "../../pages/custom/appUsers/appUsers.service";
import { AppUser } from "../../core/model/appuser";
import { DoctorTransactionsService } from "../../pages/custom/doctorTransactions/doctorTransactions.service";
import { DealerTransactionsService } from "../../pages/custom/dealerTransactions/dealerTransactions.service";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @ViewChild('itemForm') itemForm: any;
  @Input() header: boolean = false;
  userInfo: AppUser;
  selectedItem: any;
  passwordChangeDialog: boolean = false;
  newPassword: any;
  confirmPassword11: any;
  passwordStatus: boolean = false;
  showSuccessDialog: boolean = false;
  doctorTransactionPoints: any;
  myPoints: number = 0;
  totalPoint: number = 0;
  passwordEmpty: boolean = false;
  rechargeList: any = [];
  loading: boolean = false;
  constructor(
    public helperService: HelperService,
    private usersService: AppUsersService,
    public totalRechargesService: TotalRechargesService,
    public rechargesService: RechargesService,
    public loginsService: LoginsService,
    public doctorTransactionsService: DoctorTransactionsService,
    public dealerTransactionsService: DealerTransactionsService,
    public router: Router
  ) {
    this.userInfo = this.helperService.getLoggedInUserInfo();
    this.getMyPoints();
    this.helperService.dataChange.subscribe((type) => {
      if (type == 'point-change') {
        this.getMyPoints();
      }
    });
  }

  ngOnInit(): void {
    this.userInfo = this.helperService.getLoggedInUserInfo();
    this.getMyPoints();
  }

  getMyPoints() {

    if (this.userInfo?.role == "DOCTOR") {
      const condition = {};
      condition["appuserId"] = this.userInfo?.clientId;
      this.doctorTransactionsService
        .calculateDoctorPoints(condition)
        .subscribe((data) => {
          this.totalPoint = 0;
          this.myPoints = 0;
          this.doctorTransactionPoints = (data as any).doctorTransactionPoints;
          this.myPoints = this.doctorTransactionPoints[0].totalPoints;

        });
    }
    if (this.userInfo?.role == "Dealer") {
      this.dealerTransactionsService.getDealerPoints(this.userInfo?.clientId, this.userInfo?.id)
        .subscribe((data) => {
          this.totalPoint = 0;
          this.myPoints = 0;
          let totalPoint = (data as any).calculateDealerPoints;
          this.totalPoint = totalPoint[0].totalpoints;
          let redeemPoint = (data as any).dealerTransactionPoints;

          this.myPoints = this.totalPoint - redeemPoint[0].totalPoints;

        });
    }
    if (this.userInfo?.role == "CLIENT") {

      const totalRecharges = this.totalRechargesService.getAll(true);
      const recharges = this.rechargesService.getAll(true);
      forkJoin([
        totalRecharges,
        recharges
      ]).subscribe((results) => {
        this.totalPoint = 0;
        this.myPoints = 0;
        this.myPoints = results[0][0].actualAmount;
        this.rechargeList = results[1].filter(a => a.paymentStatus == 'Success');
        this.rechargeList.forEach(element => this.totalPoint = this.totalPoint + element.actualAmount);
        this.totalPoint = this.totalPoint > 0 ? this.totalPoint : 0;
        console.log('recharge11', this.totalPoint, this.myPoints, this.rechargeList, this.totalPoint);
      });

      // this.totalRechargesService.getAll(true).subscribe((data) => {
      //   this.myPoints = data[0].actualAmount;
      // });
    }
  }

  refreashToken() {
    let expireTokenDate = this.helperService.getExpireToken();
    expireTokenDate = expireTokenDate.replace('"', "").replace('"', "");
    this.usersService.RefreashToken(expireTokenDate).subscribe((data) => {
      if (data.status === 1) {
        this.helperService.setToken(data.token);
      } else {
        this.logout();
      }
    });
  }

  logout() {
    this.loginsService.clearData();
    this.helperService.logout();
    this.helperService.routeNavigate("login");
  }
  goto(path: string) {
    this.helperService.routeNavigate(path);
  }

  passwordMatch() {
    this.loading = true;
    let condition = {};
    condition["Id"] = this.userInfo?.id;
    this.usersService.searchData(null, null, condition).subscribe((data) => {
      this.selectedItem = data;
      this.selectedItem[0].password = this.newPassword;
      this.selectedItem[0].id = this.userInfo?.id;

      if (this.newPassword?.length < 1 && this.confirmPassword11?.length < 1) {
        this.passwordEmpty = true;
        this.passwordStatus = false;
        this.loading = false;
      } else if (this.newPassword === this.confirmPassword11 && this.newPassword != undefined && this.confirmPassword11 != undefined) {
        this.passwordStatus = false;
        this.passwordEmpty = false;
        this.usersService
          .updatePassword11(this.selectedItem[0])
          .subscribe((data) => {
            this.loading = false;
            if (data == "Success") {
              this.passwordChangeDialog = false;
              this.showSuccessDialog = true;
              this.helperService.routeNavigate("/patient/doctor");
              this.newPassword = '';
              this.confirmPassword11 = '';
              this.itemForm.reset();
            }
          });
      } else {
        this.passwordStatus = true;
        this.passwordEmpty = false;
        this.loading = false;
      }
    });
  }
}
