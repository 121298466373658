import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { PatientHistory } from '../../../core/model';
import { PatientHistoriesService as service } from '../../user/patientHistories/patientHistories.service';

@Injectable({
    providedIn: 'root'
})

export class PatientHistoriesService extends service
 {
    addPatientHistory(item: PatientHistory) {
        return this.http.post(this.apiUrl + 'AddPatientHistory', item)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(this.handleError)
        );
      }
  
      getCommentsData(reportId:number)
      {
        const form={'rid':reportId,'value':0};
        return this.http.post(this.apiUrl + 'GetCommentsData',form)
        .pipe(
          map(data => {
            return data;
          }),
          catchError(this.handleError)
        );
      }
}
