export * from './logins.service'
export * from './users.service';
export * from './blogs.service';
export * from './webUsers.service';
export * from './enumDetails.service';
export * from './appUsers.service';
export * from './commonDatas.service';
export * from './commonDataDefaults.service';
export * from './configurations.service';
export * from './contactUs.service';
export * from './contentHtmls.service';
export * from './contentHtmlDefaults.service';
export * from './doctorCharges.service';
export * from './doctorClients.service';
export * from './documents.service';
export * from './homes.service';
export * from './i18n.service';
export * from './importDatas.service';
export * from './logins.service';
export * from './recharges.service';
export * from './reports.service';
export * from './reportDetails.service';
export * from './totalRecharges.service';
export * from './otps.service';
export * from './dealers.service';
export * from './dealerTransactions.service';
export * from './doctorTransactions.service';
export * from './templates.service';
export * from './modalities.service';
export * from './patientHistories.service';
export * from './reportFiles.service';
export * from './reportHistories.service';
export * from './forgetPasswords.service';
export * from './allDocumentUploads.service';
// <<export-service>>
