import { Injectable } from '@angular/core';
import { DealerTransaction } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class DealerTransactionsService extends BaseService<DealerTransaction> {
    constructor() {
        super('DealerTransaction');
    }
}
