import { Injectable } from '@angular/core';
import { Template } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class TemplatesService extends BaseService<Template> {
    constructor() {
        super('Template');
    }
}
