import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';


declare var Razorpay:any;



@Component({
  selector: 'app-razorpay',
  templateUrl: './razorpay.component.html',
  styleUrls: ['./razorpay.component.scss']
})
export class RazorpayComponent implements OnInit {
  @Input() orderData: {
    order_id: string;
    key: string;
    image: string; amount: string; description: string; orderId: string; name: string; prefill: { email: string; contact: string; };
};

@Output() razorResponse = new EventEmitter<any>();


options: object;

  message: string;

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderData) {
      this.viewData();
    }
  }
  viewData(){
    this.options = {
      "key": this.orderData.key, // Enter the Key ID generated from the Dashboard
      // "key": "rzp_test_wjeAnNYApeP3eB", // Enter the Key ID generated from the Dashboard
      "amount": this.orderData.amount.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": this.orderData.name,
      "description":this.orderData.description,
      "image": this.orderData.image,
      "order_id": this.orderData.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "handler": (response: any) =>{
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
          this.razorResponse.emit(response);
      },
      "prefill": {
          "name": this.orderData.name,
          "email": this.orderData.prefill.email,
          "contact": this.orderData.prefill.contact
      },
      "notes": {
          "address": "Razorpay Corporate Office"
      },
      "theme": {
          "color": "#6827ab"
      },
      "modal": {
        "ondismiss": function(response: any) {
            this.goback();
        }.bind(this)
    }
  };
  this.payNow();
  }
  goback(){
    var response={
       status:'Error'
     }
     this.razorResponse.emit(response);
   }
  payNow(){
    // return
    let rzp1 = new Razorpay(this.options);
    rzp1.open();
    rzp1.on('payment.failed', function (response){
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
});
// document.getElementById('rzp-button1').onclick = function(e){
//     rzp1.open();
//     e.preventDefault();
// }
  }
  @HostListener('window:payment.success',['$event'])
  onPaymentSuccess(event:any): void{
   this.message = "Success Payment"
  }
}


