import { Component, OnInit } from '@angular/core';
import { IDicomConfig, ITool, IViewerProvider, ToolModeEnum } from 'ngx-dicom';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  // imagePath: any = '0015.DCM';
  imagePath: any = 'series-00000/image-00000.dcm';
  showDicomViewer: boolean = true;
  url = 'http://asset.wazl.in/DicomViwer/';
  config: IDicomConfig;
  viewerProvider: IViewerProvider | undefined;
  ngOnInit(): void {
    // this.view();

  }

  constructor(
    private cdr: ChangeDetectorRef
  ) {

  }
  // view() {
  //   this.config = {
  //     fileUrl: this.url + this.imagePath,
  //     tools: [
  //       {
  //         name: 'DragProbe',
  //         icon: 'ri-drag-move-2-line',
  //         options: { mouseButtonMask: 1 },
  //         mode: ToolModeEnum.Passive
  //       },
  //       {
  //         name: 'Eraser',
  //         icon: 'ri-eraser-line',
  //         options: { mouseButtonMask: 1 },
  //         mode: ToolModeEnum.Passive
  //       },
  //       {
  //         name: 'Magnify',
  //         icon: 'ri-zoom-in-line',
  //         options: { mouseButtonMask: 1 },
  //         mode: ToolModeEnum.Passive
  //       },
  //       {
  //         name: 'StackScrollMouseWheel',
  //         icon: 'ri-mouse-line',
  //         options: { mouseButtonMask: 1 },
  //         mode: ToolModeEnum.Active
  //       },
  //       {
  //         name: 'Rotate',
  //         icon: 'ri-clockwise-2-line',
  //         options: { mouseButtonMask: 1 },
  //         mode: ToolModeEnum.Passive
  //       },
  //       {
  //         name: 'Pan',
  //         icon: 'ri-expand-left-right-fill',
  //         options: { mouseButtonMask: 1 },
  //         mode: ToolModeEnum.Passive
  //       },
  //       {
  //         name: 'ZoomMouseWheel',
  //         icon: 'ri-zoom-in-line',
  //         options: { mouseButtonMask: 1 },
  //         mode: ToolModeEnum.Passive
  //       },
  //       {
  //         name: 'Length',
  //         icon: 'ri-ruler-line',
  //         options: { mouseButtonMask: 1 },
  //         mode: ToolModeEnum.Passive
  //       },
  //       {
  //         name: 'Angle',
  //         icon: 'ri-checkbox-blank-line',
  //         options: { mouseButtonMask: 1 },
  //         mode: ToolModeEnum.Passive
  //       },
  //       {
  //         name: 'FreehandRoi',
  //         icon: 'ri-pencil-line',
  //         options: { mouseButtonMask: 1 },
  //         mode: ToolModeEnum.Passive
  //       },
  //       {
  //         name: 'Wwwc',
  //         icon: 'ri-contrast-line',
  //         options: { mouseButtonMask: 1 },
  //         mode: ToolModeEnum.Passive
  //       }
  //     ],
  //     classList: 'canvas-container'
  //   };

  //   console.log('this.configthis.config', this.config);
  // }
  configuration() {

  }

  changedcm(dcmpath: string) {
    this.imagePath = dcmpath;
    // this.view();
    // fileUrl: this.url+this.imagePath;
    // this.config.fileUrl = `${window.location.origin}/assets/${this.imagePath}`;
    this.showDicomViewer = false;
    setTimeout(() => {
      this.showDicomViewer = true;
    }, 0);

  }
  exportStateToJson(): void {
    this.download(
      JSON.stringify(this.viewerProvider?.cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState()),
      'toolsState.json',
      'application/json'
    )
  }

  saveAs(): void {
    this.viewerProvider?.cornerstoneTools.SaveAs(this.viewerProvider?.element, 'screenshot.png');
  }

  activateTool(name: string): void {
    const foundTool = this.config.tools?.find((tool) => tool.name === name);
    if (foundTool && this.viewerProvider) {
      this.viewerProvider.cornerstoneTools['setToolActive'](name, foundTool.options)
    }
  }

  isActive(tool: ITool): boolean {
    return this.viewerProvider?.cornerstoneTools.isToolActiveForElement(this.viewerProvider?.element, tool.name);
  }

  private download(content: any, fileName: string, contentType: string) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }



}
