import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./pages/auth/guard/authGuard.service";
import { AuthAdminGuard } from "./pages/auth/guard/authGuardAdmin.service";
import { UsersProfileComponent } from "./pages/auth/users-profile/users-profile.component";


const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "login" },
  { path: 'admin/login', loadChildren: () => import('./pages/admin/logins/logins.module').then(m => m.LoginsModule) },

  { path: "register1", loadChildren: () => import("./pages/auth/register/register.module").then((m) => m.RegisterModule), },
  { path: "login/dealer", loadChildren: () => import("./pages/auth/logindealer/login.module").then((m) => m.LoginModule) },
  { path: "login", loadChildren: () => import("./pages/auth/login/login.module").then((m) => m.LoginModule) },
  { path: "verify", loadChildren: () => import("./pages/auth/verification/verification.module").then((m) => m.VerificationModule) },
  { path: 'admin/email-verification', loadChildren: () => import('./pages/auth/email-verification/email-verification.module').then(m => m.EmailVerificationPageModule), canActivate: [AuthGuard] },

  // For Admin Route
  { path: 'admin/users', loadChildren: () => import('./pages/user/users/users.module').then(m => m.UsersModule), canActivate: [AuthAdminGuard]  },
  { path: 'admin/enumDetails', loadChildren: () => import('./pages/adminCustom/enumDetails/enumDetails.module').then(m => m.EnumDetailsModule), canActivate: [AuthAdminGuard]  },

{ path: 'admin/clientReject/appUsers', loadChildren: () => import('./pages/adminCustom/appUsers/appUsers.module').then(m => m.AppUsersModule) , canActivate: [AuthAdminGuard] },
{ path: 'admin/doctorReject/appUsers', loadChildren: () => import('./pages/adminCustom/appUsers/appUsers.module').then(m => m.AppUsersModule) , canActivate: [AuthAdminGuard] },
{ path: 'admin/dealer/appUsers', loadChildren: () => import('./pages/adminCustom/appUsers/appUsers.module').then(m => m.AppUsersModule) , canActivate: [AuthAdminGuard] },
{ path: 'admin/client/appUsers', loadChildren: () => import('./pages/adminCustom/appUsers/appUsers.module').then(m => m.AppUsersModule) , canActivate: [AuthAdminGuard] },
{ path: 'admin/doctor/appUsers', loadChildren: () => import('./pages/adminCustom/appUsers/appUsers.module').then(m => m.AppUsersModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/sparkDoctor/appUsers', loadChildren: () => import('./pages/adminCustom/appUsers/appUsers.module').then(m => m.AppUsersModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/appUsers', loadChildren: () => import('./pages/adminCustom/appUsers/appUsers.module').then(m => m.AppUsersModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/commonDatas', loadChildren: () => import('./pages/admin/commonDatas/commonDatas.module').then(m => m.CommonDatasModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/commonDataDefaults', loadChildren: () => import('./pages/admin/commonDataDefaults/commonDataDefaults.module').then(m => m.CommonDataDefaultsModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/configurations', loadChildren: () => import('./pages/adminCustom/configurations/configurations.module').then(m => m.ConfigurationsModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/contactUs', loadChildren: () => import('./pages/adminCustom/contactUs/contactUs.module').then(m => m.ContactUsModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/contentHtmls', loadChildren: () => import('./pages/admin/contentHtmls/contentHtmls.module').then(m => m.ContentHtmlsModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/contentHtmlDefaults', loadChildren: () => import('./pages/admin/contentHtmlDefaults/contentHtmlDefaults.module').then(m => m.ContentHtmlDefaultsModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/doctorCharges', loadChildren: () => import('./pages/adminCustom/doctorCharges/doctorCharges.module').then(m => m.DoctorChargesModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/doctorClients', loadChildren: () => import('./pages/adminCustom/doctorClients/doctorClients.module').then(m => m.DoctorClientsModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/documents', loadChildren: () => import('./pages/admin/documents/documents.module').then(m => m.DocumentsModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/home', loadChildren: () => import('./pages/adminCustom/homes/homes.module').then(m => m.HomesModule), canActivate: [AuthAdminGuard] },
{ path: 'admin/i18n', loadChildren: () => import('./pages/admin/i18n/i18n.module').then(m => m.I18nModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/importDatas', loadChildren: () => import('./pages/admin/importDatas/importDatas.module').then(m => m.ImportDatasModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/logins', loadChildren: () => import('./pages/admin/logins/logins.module').then(m => m.LoginsModule) },
{ path: 'admin/recharges', loadChildren: () => import('./pages/adminCustom/recharges/recharges.module').then(m => m.RechargesModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/reports', loadChildren: () => import('./pages/adminCustom/reports/reports.module').then(m => m.ReportsModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/reportDetails', loadChildren: () => import('./pages/adminCustom/reportDetails/reportDetails.module').then(m => m.ReportDetailsModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/totalRecharges', loadChildren: () => import('./pages/adminCustom/totalRecharges/totalRecharges.module').then(m => m.TotalRechargesModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/homes', loadChildren: () => import('./pages/admin/homes/homes.module').then(m => m.HomesModule) , canActivate: [AuthAdminGuard] },
{ path: 'admin/otps', loadChildren: () => import('./pages/admin/otps/otps.module').then(m => m.OtpsModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/dealers', loadChildren: () => import('./pages/adminCustom/dealers/dealers.module').then(m => m.DealersModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/dealerTransactions', loadChildren: () => import('./pages/adminCustom/dealerTransactions/dealerTransactions.module').then(m => m.DealerTransactionsModule) , canActivate: [AuthAdminGuard] },
{ path: 'admin/doctorTransactions', loadChildren: () => import('./pages/adminCustom/doctorTransactions/doctorTransactions.module').then(m => m.DoctorTransactionsModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/templates', loadChildren: () => import('./pages/adminCustom/templates/templates.module').then(m => m.TemplatesModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/modalities', loadChildren: () => import('./pages/adminCustom/modalities/modalities.module').then(m => m.ModalitiesModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/patientHistories', loadChildren: () => import('./pages/adminCustom/patientHistories/patientHistories.module').then(m => m.PatientHistoriesModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/reportFiles', loadChildren: () => import('./pages/adminCustom/reportFiles/reportFiles.module').then(m => m.ReportFilesModule), canActivate: [AuthAdminGuard]  },
{ path: 'admin/reportHistories', loadChildren: () => import('./pages/admin/reportHistories/reportHistories.module').then(m => m.ReportHistoriesModule) },
{ path: 'admin/forgetPasswords', loadChildren: () => import('./pages/admin/forgetPasswords/forgetPasswords.module').then(m => m.ForgetPasswordsModule) },
{ path: 'admin/allDocumentUploads', loadChildren: () => import('./pages/adminCustom/allDocumentUploads/allDocumentUploads.module').then(m => m.AllDocumentUploadsModule) },
// <<route-path-admin>>
  // End Admin Route

  // For AppUser Route
    // {path:'home',component:HomeComponent},
  // { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },

    {path:'profile',component:UsersProfileComponent},
  { path: 'users', loadChildren: () => import('./pages/user/users/users.module').then(m => m.UsersModule), canActivate: [AuthGuard] },
{ path: 'webUsers', loadChildren: () => import('./pages/user/webUsers/webUsers.module').then(m => m.WebUsersModule), canActivate: [AuthGuard] },
{ path: 'appUsers', loadChildren: () => import('./pages/custom/appUsers/appUsers.module').then(m => m.AppUsersModule), canActivate: [AuthGuard] },
{ path: 'contactUs', loadChildren: () => import('./pages/custom/contactUs/contactUs.module').then(m => m.ContactUsModule), canActivate: [AuthGuard] },
{ path: 'doctorCharges', loadChildren: () => import('./pages/custom/doctorCharges/doctorCharges.module').then(m => m.DoctorChargesModule), canActivate: [AuthGuard] },
{ path: 'doctorClients', loadChildren: () => import('./pages/custom/doctorClients/doctorClients.module').then(m => m.DoctorClientsModule), canActivate: [AuthGuard] },
{ path: 'home', loadChildren: () => import('./pages/custom/homes/homes.module').then(m => m.HomesModule), canActivate: [AuthGuard] },
{ path: 'recharges', loadChildren: () => import('./pages/custom/recharges/recharges.module').then(m => m.RechargesModule), canActivate: [AuthGuard] },
{ path: 'patient', loadChildren: () => import('./pages/custom/reports/reports.module').then(m => m.ReportsModule), canActivate: [AuthGuard] },
// { path: 'client/patient', loadChildren: () => import('./pages/custom/reports/reports.module').then(m => m.ReportsModule) },
// { path: 'doctor/report', loadChildren: () => import('./pages/custom/reports/reports.module').then(m => m.ReportsModule) },
// { path: 'client/report', loadChildren: () => import('./pages/custom/reports/reports.module').then(m => m.ReportsModule) },
{ path: 'reportDetails', loadChildren: () => import('./pages/custom/reportDetails/reportDetails.module').then(m => m.ReportDetailsModule), canActivate: [AuthGuard] },
{ path: 'totalRecharges', loadChildren: () => import('./pages/custom/totalRecharges/totalRecharges.module').then(m => m.TotalRechargesModule), canActivate: [AuthGuard] },
{ path: 'homes', loadChildren: () => import('./pages/user/homes/homes.module').then(m => m.HomesModule), canActivate: [AuthGuard] },
{ path: 'dealers', loadChildren: () => import('./pages/custom/dealers/dealers.module').then(m => m.DealersModule), canActivate: [AuthGuard] },
{ path: 'dealerTransactions', loadChildren: () => import('./pages/custom/dealerTransactions/dealerTransactions.module').then(m => m.DealerTransactionsModule), canActivate: [AuthGuard] },
{ path: 'doctorTransactions', loadChildren: () => import('./pages/custom/doctorTransactions/doctorTransactions.module').then(m => m.DoctorTransactionsModule), canActivate: [AuthGuard] },
{ path: 'templates', loadChildren: () => import('./pages/custom/templates/templates.module').then(m => m.TemplatesModule), canActivate: [AuthGuard] },
{ path: 'modalities', loadChildren: () => import('./pages/user/modalities/modalities.module').then(m => m.ModalitiesModule), canActivate: [AuthGuard] },
{ path: 'patientHistories', loadChildren: () => import('./pages/custom/patientHistories/patientHistories.module').then(m => m.PatientHistoriesModule), canActivate: [AuthGuard] },
{ path: 'reports', loadChildren: () => import('./pages/user/reports/reports.module').then(m => m.ReportsModule), canActivate: [AuthGuard] },
{ path: 'allDocumentUploads', loadChildren: () => import('./pages/custom/allDocumentUploads/allDocumentUploads.module').then(m => m.AllDocumentUploadsModule) },
// <<route-path-user>>
  // End AppUser Route
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
