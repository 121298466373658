import { Injectable } from '@angular/core';
import { HelperServiceBase } from '../sharedBase/helperService';
//import { HelperServiceBase } from '@ojas-enterprise/baseapp/dist/sharedBase';

@Injectable({
  providedIn: 'root'
})
export class HelperService extends HelperServiceBase {
  override homePage = 'testboard';

  setActiveRouteData(path: string) {
    const currentUrl = this.router.url;
    const routeUrl = currentUrl.split("#")[0];
    return routeUrl.toLowerCase() == path.toLowerCase();
  }

  getFooterPages() {
    const pages = [
      {
        icon: "home.svg",
        text: "Home",
        url: "/home",
      },
      {
        icon: "platters.svg",
        text: "Platters",
        url: "/platters/home",
      },
      {
        icon: "my-portfolio.svg",
        text: "Portfolio",
        url: "/investment-report",
      },
      {
        icon: "funds.svg",
        text: "Funds",
        url: "/funds/home",
      },
      {
        icon: "accounts.svg",
        text: "Tools",
        url: "/tools",
      },
    ];
    return [...pages];
  }

  public convertToMinSec(totalSeconds: number): string {
    totalSeconds = Math.round(totalSeconds);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }
  public convertTohrMin(totalMinit: number): string {
    totalMinit = Math.round(totalMinit);
    const hours = Math.floor(totalMinit / 60);
    const minutes = totalMinit % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }
}
