import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { DoctorTransaction } from '../../../core/model';
import { DoctorTransactionsService as service } from '../../user/doctorTransactions/doctorTransactions.service';


@Injectable({
    providedIn: 'root'
})

export class DoctorTransactionsService extends service {
    addPoints(item: any) {
        return this.http.post(this.apiUrl + 'Add', item)
            .pipe(
                map(data => {

                    return data;
                }),

                catchError(this.handleError)

            );
    }

    calculateDoctorPoints(condition: any) {
        const form = { form: null, condition: condition };
        return this.http.post(this.apiUrl + 'CalculateDoctorPoints', form)
            .pipe(
                map(data => {
                    return data;
                }),
                catchError(this.handleError)
            );
    }

}
