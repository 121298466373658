import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IFileUpload } from '../../shared/file-upload/file-upload.component';
import { EnvService } from '../../sharedBase/env.service';

@Component({
  selector: 'app-file-upload-view',
  templateUrl: './fileUploadView.component.html',
  styleUrls: ['./fileUploadView.component.scss'],
})
export class FileUploadViewComponent implements OnInit {

  @Input() fileJson: string;
  @Input() service: any;
  @Input() showImage: boolean = false;
  @Input() noCSS: boolean = false;
  @Output() viewImgUrl = new EventEmitter<any>();
  apiBaseUrl = '';
  uploadedFiles: [];
  imageShowUrl: string;
  imageShowDialog: boolean = false;
  constructor(
    public envService: EnvService
  ) {
    this.apiBaseUrl = this.envService.apiUrl;
  }

  ngOnInit() {
    if (this.fileJson && this.fileJson.length > 10) {
      this.uploadedFiles = JSON.parse(this.fileJson);
    }
  }

  saveExportFile(blob: Blob, fileName: string) {
    const nav = (window.navigator as any);
    if (nav.msSaveOrOpenBlob) { // For IE:
      nav.msSaveBlob(blob, fileName);
    } else { // For other browsers:
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
  }

  downloadFile(file: IFileUpload) {
    this.service.fileDownload(file).subscribe((fileData) => {
      const blob = new Blob([fileData]);
      this.saveExportFile(blob, file.fileName);
    });
  }
  openImageViewer(item: any) {
    this.viewImgUrl.emit(JSON.stringify([item]));
  }
}
