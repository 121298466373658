import { Injectable } from '@angular/core';
import { ForgetPassword } from '../model';
import { catchError, map } from 'rxjs/operators';
// import { ForgetPasswordsService as service } from '../../core/service';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class ForgetPasswordsService extends BaseService<ForgetPassword> {
    // export class ForgetPasswordsService extends service {
  
    constructor() {
        super('ForgetPassword');
    }
    MailOTP(EmailId:string) {
        const form = {'EmailId':EmailId,'AppUserId':''};
          return this.http.post(this.apiUrl + 'MailOTP', form)
          .pipe(
              map(data => {
                return data;
              }),
              catchError(this.handleError)
            );
        }
        verifyOTP(otp:any,EmailId:string) {
            const form = {'EmailId':EmailId,'otp':otp,'AppUserId':''};
              return this.http.post(this.apiUrl + 'verifyOTP', form)
              .pipe(
                  map(data => {
                    return data;
                  }),
                  catchError(this.handleError)
                );
            }
            updatePassword(otp:string,EmailId:string,Password:string) {
                const form = {'EmailId':EmailId,'SecretCode':otp,'Password':Password};
                return this.http.post(this.apiUrl + 'updatePassword', form)
                .pipe(
                    map(data => {
                      return data;
                    }),
                    catchError(this.handleError)
                  );
              }
}
