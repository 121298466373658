import { Injectable } from '@angular/core';
import { PatientHistory } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class PatientHistoriesService extends BaseService<PatientHistory> {
    constructor() {
        super('PatientHistory');
    }
}
