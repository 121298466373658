import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable, timer, throwError } from 'rxjs';
import { map, catchError, switchMap, retry } from 'rxjs/operators';
import { ObservableStore } from '@codewithdan/observable-store';
import { StoreStateNew } from './interfaces';
import { AppInjectorBase } from './app-injector.service';
import { BaseModel } from './modelInterface';
import { Router } from '@angular/router';
import { HelperServiceBase } from './helperService';
import { EnvService } from './env.service';

interface IStoreActions {
  GetAll: string;
  GetData: string;
  GetSearch: string;
  GetHomeCommon: string;
  GetHomeUser: string;
  GetHtmlData: string;
}


export abstract class BaseService<T extends BaseModel> extends ObservableStore<StoreStateNew<T>> {

  protected apiUrl = '';
  protected apiBaseUrl = '';
  protected http: HttpClient;
  public router: Router;
  public helperSvc: HelperServiceBase;
  public stateName: string;
  public env: EnvService;
  protected EnumStoreActions: IStoreActions = {
    GetAll: 'get_List',
    GetData: 'get_Data',
    GetSearch: 'get_SearchList',
    GetHomeCommon: 'get_Home_Common',
    GetHomeUser: 'get_Home_User',
    GetHtmlData: 'get_Html_Data',
  };
  public static helperServiceObj: HelperServiceBase;

  public condition: any = null;
  constructor(public type: string, helperSvc: any = null) {
    super({ trackStateHistory: false });
    // this.http = new HttpClient(this.httpHandler);
    const injector = AppInjectorBase.getInjector();
    this.helperSvc = BaseService.helperServiceObj;
    this.router = injector.get(Router);
    this.http = injector.get(HttpClient);
    this.env = injector.get(EnvService);
    this.stateName = type;

    this.apiBaseUrl = this.env.apiUrl;
    this.apiUrl = this.apiBaseUrl + type + '/';

    this.EnumStoreActions.GetAll = 'get_' + type + '_List';
    this.EnumStoreActions.GetData = 'get_' + type + '_Data';
    this.EnumStoreActions.GetSearch = 'get_' + type + '_Search';

    this.EnumStoreActions.GetHomeCommon = 'get_' + type + '_Home_Common';
    this.EnumStoreActions.GetHomeUser = 'get_' + type + '_Home_User';
    this.EnumStoreActions.GetHtmlData = 'get_' + type + '_Html_Data';

    this.helperSvc.loginChange.subscribe(loginType => {

      const state: any = this.getState();

      // this.setState(obj, this.EnumStoreActions.GetData);

      const listName = this.stateName + 'List';
      if (state && state[listName]) {
        console.log('Exist this.stateHistory', this.stateHistory);
        console.log('loginType:' + listName, state[listName]);
        const obj: any = {};
        obj[listName] = null;
        this.setState(obj, this.EnumStoreActions.GetAll);
        ObservableStore.clearState();
        console.log('Latest Exist this.stateHistory', this.stateHistory);
        console.log('Latest loginType:' + listName, state[listName]);
        // return of(state[listName]);
      }


    });

  }

  public static get helperService() {
    return BaseService.helperServiceObj;
  }

public static setHelperService(helperSvc: any){
  BaseService.helperServiceObj = helperSvc;
}

  public importExcel(needSampleData) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      })
    };
    return this.http.post(this.apiUrl + 'DownloadImportExcelFile', { "needSampleData" : needSampleData, "rowsCount": 50 }, httpOptions)
      .pipe(
        map(fileData => {
          console.log('fileExcel fileInfo', fileData);
          return fileData;
        }),
        catchError(this.handleError)
      );

  }

  public checkImportData() {
    return this.http.post<any[]>(this.apiUrl + 'ImportCheck',{})
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  public getImportData(file) {
    return this.http.post<any[]>(this.apiUrl + 'ImportData', file)
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  public syncImportData(file) {
    return this.http.post<any[]>(this.apiUrl + 'ImportDataSync', file)
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  public clearData() {
    const state = this.getState();
    console.log('Clear Data Before', state);
    const nullState: any = null;
    ObservableStore.clearState(true);
    this.setState(nullState);
    const state1 = this.getState();
    console.log('Clear Data After', state1);
  }

  public clearSearchData() {
    const state: any = this.getState();
    const searchValue = this.helperSvc.searchHistory;
    if (state) {
      Object.keys(state).forEach(key => {
        console.log(key, state[key]);
        if (key.indexOf('Search_') >= 0) {
          const search = this.helperSvc.searchHistory.filter(a => a.path === key);
          if (!search || !search.length) {
            const obj: any = {};
            obj[key] = null;
            this.setState(obj, this.EnumStoreActions.GetSearch);
          }
        }
      });
    }
    // this.setState(null);
  }

  public fetchData(userFilter: boolean = false) {
    let listName = this.stateName + 'List';
    let condition = this.condition;
    if (userFilter) {
      const loggedInUser = this.helperSvc.getLoggedInUserInfo();
      console.log('loggedInUser', loggedInUser, loggedInUser.id);
      const userCondition = { AppUserId: loggedInUser ? loggedInUser.id : 0 };
      condition = { ...this.condition || {}, ...userCondition };
      listName = 'User_' + this.stateName + 'List';
    }

    return this.http.post<T[]>(this.apiUrl + 'Get', { form: null, condition: condition })
      .pipe(
        map(dataList => {
          // if (dataList.length) {
            dataList=dataList.reverse();
          const obj: any = {};
          obj[listName] = dataList;
          this.setState(obj, this.EnumStoreActions.GetAll);
          // }
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  // public searchData(search: any, order: string[]) {
  //   return this.http.post<T[]>(this.apiUrl + 'Search', { form: search, condition: this.condition, orderColumns: order })
  //     .pipe(
  //       map(dataList => {
  //         const listName = 'Search_' + this.stateName + 'List';
  //         const obj = {};
  //         obj[listName] = dataList;
  //         this.setState(obj, this.EnumStoreActions.GetSearch);

  //         return dataList;
  //       }),
  //       catchError(this.handleError)
  //     );
  // }

  public fetchHomeCommon(type:string, pageType:string, condition: any) {
    let listName = this.stateName + 'HomeCommonList';
    return this.http.post<any[]>(this.apiUrl + 'GetHomeCommonData', { type:type, pageType: pageType, condition: condition })
      .pipe(
        map(dataList => {
          const obj: any = {};
          obj[listName] = dataList;
          this.setState(obj, this.EnumStoreActions.GetHomeCommon);
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  public fetchHomeUser(type:string, pageType:string, condition: any) {
    let listName = this.stateName + 'HomeUserList';
    return this.http.post<any[]>(this.apiUrl + 'GetHomeUserData', { type:type, pageType:pageType, condition: condition })
      .pipe(
        map(dataList => {
          const obj: any = {};
          obj[listName] = dataList;
          this.setState(obj, this.EnumStoreActions.GetHomeUser);
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  public fetchHtmlData(type: string, pageType: string, language: string, condition: any) {
    let listName = this.stateName + 'HtmlDataList';
    return this.http.post<any[]>(this.apiUrl + 'GetHtmlData', { type: type, pageType: pageType, language: language, condition: condition })
      .pipe(
        map(dataList => {
          const obj: any = {};
          obj[listName] = dataList;
          this.setState(obj, this.EnumStoreActions.GetHtmlData);
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  getHomeCommon(reload: boolean = false, type:string, pageType: string, condition: any) {
    const state: any = this.getState();
    let listName = this.stateName + 'HomeCommonList';
    if (!reload && state && state[listName]) {
      return of(state[listName]);
    } else {
      return this.fetchHomeCommon(type, pageType, condition)
        .pipe(
          catchError(this.handleError)
        );
    }
  }

  getHomeUser(reload: boolean = false, type:string, pageType: string, condition: any) {
    const state: any = this.getState();
    let listName = this.stateName + 'HomeUserList';
    if (!reload && state && state[listName]) {
      return of(state[listName]);
    } else {
      return this.fetchHomeUser(type, pageType, condition)
        .pipe(
          catchError(this.handleError)
        );
    }
  }

  getHtmlData(reload: boolean = false,type: string, pageType: string, language: string, condition: any) {
    const state: any = this.getState();
    let listName = this.stateName + 'HtmlDataList';
    if (!reload && state && state[listName]) {
      return of(state[listName]);
    } else {
      return this.fetchHtmlData(type, pageType, language, condition)
        .pipe(
          catchError(this.handleError)
        );
    }
  }



  public searchData(search: any, order: string[], condition: any, userFilter: boolean = false) {
    let searchCondition = condition || this.condition;
    if (userFilter) {
      const loggedInUser = this.helperSvc.getLoggedInUserInfo();
      searchCondition = searchCondition || {};
      searchCondition.AppUserId = loggedInUser ? loggedInUser.id : 0;
    }
    return this.http.post<T[]>(this.apiUrl + 'Search', { form: search, condition: searchCondition, orderColumns: order })
      .pipe(
        map(dataList => {
          const listName = 'Search_' + this.stateName + 'List';
          const obj: any = {};
          obj[listName] = dataList;
          this.setState(obj, this.EnumStoreActions.GetSearch);
          return dataList;
        }),
        catchError(this.handleError)
      );
  }


  public exportExcel(search: any, order: string[]) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      })
    };
    return this.http.post(this.apiUrl + 'DownloadExcelFile', { form: search }, httpOptions)
      .pipe(
        map(fileData => {
          console.log('fileExcel fileInfo', fileData);
          return fileData;
        }),
        catchError(this.handleError)
      );

  }

  public downloadPdf(Ids: string) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      })
    };
    const condition = { 'Ids': Ids };
    return this.http.post(this.apiUrl + 'DownloadPdf', { 'condition': condition }, httpOptions)
      .pipe(
        map(fileData => {
          console.log('filePDF fileInfo', fileData);
          return fileData;
        }),
        catchError(this.handleError)
      );

  }

  refreshData(userFilter: boolean = false) {
    return this.fetchData(userFilter)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAll(userFilter: boolean = false) {
    // const userFilter = true;
    this.clearSearchData();
    const state: any = this.getState();
    // console.log('state', state);
    // pull from store cache
    let listName = this.stateName + 'List';
    const searchListName = 'Search_' + listName;
    if (state && state[searchListName]) {
      return of(state[searchListName]);
    } else {
      if (userFilter) {
        listName = 'User_' + this.stateName + 'List';
      }
      if (state && state[listName]) {
        console.log('Exist this.stateHistory', this.stateHistory);
        return of(state[listName]);
      } else {
        // doesn't exist in store so fetch from server
        console.log('not Exist');
        return this.fetchData(userFilter)
          .pipe(
            catchError(this.handleError)
          );
      }
    }
  }

  get(id: number, userFilter: boolean = false) {
    return this.getAll(userFilter)
      .pipe(
        map(dataList => {
          const filteredData = dataList.filter((fdata: { id: any; }) => fdata.id === id);
          const data = (filteredData && filteredData.length) ? filteredData[0] : null;
          const dataName = this.stateName;
          const obj: any = {};
          obj[dataName] = data;
          this.setState(obj, this.EnumStoreActions.GetData);
          return data;
        }),
        catchError(this.handleError)
      );
  }

  add(item: T, userFilter: boolean = false) {
    return this.http.post(this.apiUrl + 'Add', item)
      .pipe(
        switchMap(usr => {
          // update local store with added item data
          // not required of course unless the store cache is needed
          // (it is for the item list component in this example)
          return this.fetchData(userFilter);
        }),
        catchError(this.handleError)
      );
  }

  draft(item: T, userFilter: boolean = false) {
    return this.http.post(this.apiUrl + 'Draft', item)
      .pipe(
        map( data => {
          // update local store with added item data
          // not required of course unless the store cache is needed
          // (it is for the item list component in this example)
          console.log('draft', data);
          return data;
        }),
        catchError(this.handleError)
      );
  }

  update(item: T, userFilter: boolean = false) {
    return this.http.post(this.apiUrl + 'Update', item)
      .pipe(
        switchMap(usr => {
          // update local store with updated item data
          // not required of course unless the store cache is needed
          // (it is for the item list component in this example)
          return this.fetchData(userFilter);
        }),
        catchError(this.handleError)
      );
  }

  delete(id: number, userFilter: boolean = false) {
    const obj = { Id: id };
    return this.http.post(this.apiUrl + 'Delete', obj)
      .pipe(
        switchMap(usr => {
          // update local store since item deleted
          // not required of course unless the store cache is needed
          // (it is for the item list component in this example)
          return this.fetchData(userFilter);
        }),
        catchError(this.handleError)
      );
  }

  fileUpload(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<any>(this.apiUrl + 'FileUpload', formData)
      .pipe(
        map(fileInfo => {
          console.log('fileUpload fileInfo', fileInfo);
          return fileInfo;
        }),
        catchError(this.handleError)
      );
  }

  fileDownload(fileInfo: any) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      })
    };
    return this.http.post(this.apiUrl + 'Download', fileInfo, httpOptions)
      .pipe(
        map(fileData => {
          console.log('fileUpload fileInfo', fileData);
          return fileData;
        }),
        catchError(this.handleError)
      );
  }

  getLookup(userFilter: boolean = false) {
    return this.getAll(userFilter);
  }

  unique(name: string, item: T, condition: any) {

    return timer(1000)
      .pipe(
        switchMap(() => {
          return this.http.post(this.apiUrl + 'CheckUnique', { 'columnName': name, 'form': item, 'condition': condition })
            .pipe(
              map(isUnique => {
                return isUnique;
              }),
              catchError(this.handleError)
            );
        })
      );
  }

  protected handleError(error: any) {
    console.error('server error:', error);
    const injector = AppInjectorBase.getInjector();
    const errorRouter = injector.get(Router);
    const helperSvc = injector.get(HelperServiceBase);
    if (error.status === 401) {
      helperSvc.navigationUrl = errorRouter.url;
      //helperSvc.routeNavigate('login');
    }

    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
    }
    return throwError(error || 'Server error');
  }

}
