import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { AppUser } from '../../../core/model';
import { AppUsersService as service } from '../../user/appUsers/appUsers.service';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpResponse } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})

export class AppUsersService extends service {
  registerUser(form: AppUser) {
    return this.http.post(this.apiUrl + 'Register', form)
    .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  RefreashToken(date) {
    return this.http.post<any>(this.apiUrl + 'RefreashToken', { 'date': date })
      .pipe(
        map(dataList => {
          return dataList;
        }),
        catchError(this.handleError)
      );
  }

  getDoctorData(condition: any) {
    const form = {form: null, condition: condition};
    return this.http.post(this.apiUrl + 'GetDoctorData', form)
    .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError)
      );
  }
  setDefaultDoctor(doctorId: any,appuserId:any) {
    const form = {doctorId: doctorId, appuserId: appuserId};
    return this.http.post(this.apiUrl + 'SetDefaultDoctor', form)
    .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError)
      );
  }
  updatePassword11(form:AppUser)
  {
    return this.http.post(this.apiUrl + 'UpdatePassword', form)
    .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  generateHtmlToPdf(studyUid: string,type:boolean,selectedData:any): Observable<Blob> {
    const url = `${this.apiUrl}GenerateNDownloadPdfFromHtml`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(url, { StudyUid: studyUid,IsHeaderRequired: type }, { headers, responseType: 'blob', observe: 'response' })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return response.body;
        })
      );
  }

  exportAllFilesZip(studyUid: string, appuserId:any,selectedFileFormat:any): Observable<Blob> {
    const url = `${this.apiUrl}ExportFilesByTypeNStudyUid`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, { StudyUid: studyUid, AppuserId: appuserId, SelectedFileFormat:selectedFileFormat }, { headers, responseType: 'blob', observe: 'response' })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return response.body;
        })
      );
  }

}
