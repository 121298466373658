import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { DealerTransaction } from '../../../core/model';
import { DealerTransactionsService as service } from '../../user/dealerTransactions/dealerTransactions.service';


@Injectable({
    providedIn: 'root'
})

export class DealerTransactionsService extends service {
    addPoints(item: any) {
        return this.http.post(this.apiUrl + 'Add', item)
            .pipe(
                map(data => {

                    return data;
                }),

                catchError(this.handleError)

            );
    }


    getDealerPoints(dealerCode: string, dealerId: number) {
       const condition={'dealerCode':dealerCode,'dealerId':dealerId};
        return this.http.post(this.apiUrl + 'CalculateDealerPoints', condition)
            .pipe(
                map(data => {

                    return data;
                }),

                catchError(this.handleError)
            );
    }

}
